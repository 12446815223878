<template>
  <div class="members-directory">
    <v-toolbar
      class="d-flex white justify-center custom-header"
      elevation="0"
      height="83"
    ><h3 class="green--text text--darken-4">
      {{ $t("membersDirectory.title") }}
    </h3></v-toolbar
    >
    <ApiErrorMessage :message="apiErrorMessage"/>
    <v-container fluid class="pa-0">
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table
            :header-props="{ sortIcon: null }"
            :headers="headers"
            :items="members"
            :search="search"
            height="calc(100vh - 387px)"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            fixed-header
            show-expand
            class="color-green"
          >
            <template v-slot:item.data-table-expand="{ expand, isExpanded }">
              <v-btn
                height="40"
                small
                color="green darkan-4 green-gradient white--text"
                class="text-capitalize"
              >
                <v-icon @click="expand(!isExpanded)">mdi-share-variant</v-icon>
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" elevation="0">
                <v-form class="pa-4 form-custom">
                  <v-card class="pa-0 d-flex align-center" flat>
                    <VueTelInput
                      class="mr-3"
                      style="height: 50px; width: 100%;"
                      elevation="0"
                      dense
                      solo
                      mode="international"
                      v-model="phone"
                      defaultCountry="US"
                      :dynamicPlaceholder="true"
                      :enabledCountryCode="true"
                      :onlyCountries="['US', 'CO', 'MX', 'CA']"/>
                    <v-btn
                      height="50"
                      medium
                      @click="shareMemberInfo(item)"
                      dense
                      class="green darken-4 green-gradient white--text text-capitalize"
                      elevation="0"
                    >
                      {{ $t("membersDirectory.share") }}
                    </v-btn>
                  </v-card>
                </v-form>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    VueTelInput,
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      search: null,
      dialog: false,
      selectedMember: {},
      phone: null,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: []
    };
  },

  computed: {
    ...mapGetters({
      members: "members/getMembers",
    }),
    headers() {
      return [
        {
          text: this.$t("membersDirectory.companyName"),
          align: "left",
          value: "company_name",
        },
        {
          text: this.$t("membersDirectory.address"),
          align: "left",
          value: "full_address",
        },
        {
          text: this.$t("membersDirectory.telephone"),
          align: "left",
          value: "formatted_representative_phone",
        },
        {
          text: this.$t("membersDirectory.share"),
          align: "left",
          value: "data-table-expand",
        },
      ];
    },
  },
  async mounted() {
    this.$store.commit("setLoader", true);
    try {
      await this.getMembers();
    } catch ({message}) {
      this.apiErrorMessage = message;
    } finally {
      this.$store.commit("setLoader", false);
    }
  },

  methods: {
    ...mapActions({
      getMembers: "members/getMembers",
      shareMember: "members/shareMember",
    }),

    async shareMemberInfo(member) {
      this.$store.commit("setLoader", true);

      try {
        await this.shareMember({
          member_id: member.id,
          phone: this.phone,
        });
        this.phone = null;
        this.expanded = [];
      } catch ({message}) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
